import Logo from './Logo_Color.png';
function App() {
  return (
    <div className="App">
      <img src={Logo} id={'logo'}/>
      <div id={'textContainer'}>
        <label className={'text'}>Our new website is launching soon.</label>
        <label className={'text'}><a href={'mailto:hello@arcsmen.com'}>hello@arcsmen.com</a></label>
      </div>
    </div>
  );
}

export default App;
